import GoogleDownloadBtn from "@/assets/images/google-download.png";
import AppleDownloadBtn from "@/assets/images/appstore-download.png";
import AppPreview1 from "@/assets/images/app-preview1.png";
import AppPreview2 from "@/assets/images/app-preview2.png";
import AppPreview3 from "@/assets/images/app-preview3.png";
import AppPreview4 from "@/assets/images/app-preview4.png";

const DownloadApp = () => {
  return (
    <section className="flex flex-col items-center mt-24 md:mt-32">
      <div className="width-container bg-gray-50 rounded-[32px]">
        <div className="flex flex-col md:flex-row items-center justify-between mt2 px-4">
          <div className="py-10">
            <h2 className="text-gray-800 text-[30px] md:text-[48px] font-bold w-full max-w-[555px]">
              Download our App and Start sending Packages
            </h2>
            <p className="text-gray-700 text-sm md:text-base w-full max-w-[307px] mt-3 mb-7">
              Our Application is available to download for both our iOS and
              Android Customers
            </p>
            <div className="flex flex-row items-center gap-5 mt-10">
              <a
                href="https://apps.apple.com/ng/app/point2/id6737227162"
                className="w-[140px]"
              >
                <img src={AppleDownloadBtn} alt="" className="w-full" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.point2.user"
                className="w-[140px]"
              >
                <img src={GoogleDownloadBtn} alt="" className="w-full" />
              </a>
            </div>
          </div>
          <div className="w-full max-w-[450px]">
            <div className="flex flex-row gap-5">
              <div>
                <div className="w-full max-w-[207px]">
                  <img src={AppPreview1} alt="app review" className="w-full" />
                </div>
                <div className="w-full max-w-[207px]">
                  <img src={AppPreview2} alt="app review" className="w-full" />
                </div>
              </div>
              <div>
                <div className="w-full max-w-[207px]">
                  <img src={AppPreview3} alt="app review" className="w-full" />
                </div>
                <div className="w-full max-w-[207px]">
                  <img src={AppPreview4} alt="app review" className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
