import Hero from "./components/hero";
import Image1 from "@/assets/images/business-owner.png";
import Image2 from "@/assets/images/rider.png";
import VisionMission from "./components/vision-mission";
import Contact from "@/components/Contact";
import TeamMembers from "./components/team-members";
import Seo from "@/components/Seo";

const About = () => {
  return (
    <div className="flex-1">
      <Seo title="Point2 | about" />
      <Hero />
      <div className="mt-5 flex flex-col items-center">
        <div className="w-full max-w-[940px] px-3">
          <div className="flex flex-col md:flex-row md:items-center gap-[44px] mt-14">
            <div className="w-full max-w-[584px] rounded-2xl">
              <img src={Image1} alt="business owner" className="w-full" />
            </div>
            <div className="">
              <h2 className="text-gray-900 text-[30px] md:text-[36px] font-bold">
                What is Point2 Logistics?
              </h2>
              <p className="text-gray-700 text-base max-w-[312px] mt-3 mb-7">
                We are a licensed logistics company that is keen on giving
                technology solutions to package delivery
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row-reverse md:items-center gap-[44px] mt-14">
            <div className="w-full max-w-[584px] rounded-2xl">
              <img src={Image2} alt="business owner" className="w-full" />
            </div>
            <div className="">
              <h2 className="text-gray-900 text-[30px] md:text-[36px] font-bold">
                What do we do?
              </h2>
              <p className="text-gray-700 text-base max-w-[312px] mt-3 mb-7">
                We make your life easier. Do you need to get an item across
                Lagos? Just Order a Point2 Rider! Hungry for food? Just Order a
                Point2 Rider!
              </p>
            </div>
          </div>
        </div>
      </div>
      <VisionMission />
      <TeamMembers />
      <Contact />
    </div>
  );
};

export default About;
