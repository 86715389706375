import PrimaryButton from "@/components/Button/PrimaryButton";
import Input from "@/components/Input/Input";
import Label from "@/components/Label/Label";
import { EditProfileSchema, TEditProfile } from "@/types/validations/profile";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineMail } from "react-icons/md";
import PhoneInput from "react-phone-input-2";

const EditProfile = () => {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<TEditProfile>({
    mode: "onBlur",
    resolver: zodResolver(EditProfileSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TEditProfile> = async (data) => {
    // mutate(data);
    console.log(data);
  };

  return (
    <form className="" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-8">
        <div className="w-[50%]">
          <Input
            placeholder="enter first name"
            label="First Name"
            isRequired
            {...register("first_name")}
            error={errors.first_name?.message}
          />
        </div>
        <div className="w-[50%]">
          <Input
            placeholder="enter last name"
            label="Last Name"
            isRequired
            {...register("last_name")}
            error={errors.last_name?.message}
          />
        </div>
      </div>
      <div className="flex items-center gap-8">
        <div className="w-[50%]">
          <Label label="Phone Number" isRequired />
          <PhoneInput
            placeholder="90722245789"
            country={"ng"}
            onChange={(value) => setValue("phone", value)}
            inputStyle={{
              width: "100%",
              borderRadius: "8px",
              fontSize: "14px",
            }}
          />
          {errors.phone?.message && (
            <small className="px-3 text-red-500">{errors.phone?.message}</small>
          )}
        </div>
        <div className="w-[50%]">
          <Input
            preIcon={<MdOutlineMail size={20} className="text-gray-500" />}
            placeholder="enter email"
            label="Email address"
            isRequired
            {...register("email")}
            error={errors?.email?.message}
          />
        </div>
      </div>
      <div className="flex items-center gap-3 mt-9">
        <PrimaryButton type="submit" text="Save Changes" variant="primary" />
        <PrimaryButton
          type="button"
          text="Cancel"
          variant="light"
          className="bg-brand-25 w-[172px]"
        />
      </div>
    </form>
  );
};

export default EditProfile;
