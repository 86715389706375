import { APP_ROUTES } from "@/constants/app-routes";
import { Fragment, useState } from "react";
import { IoMdPerson } from "react-icons/io";
import { IoBagOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginSchema, TLogin } from "@/types/validations/auth";
import PrimaryButton from "@/components/Button/PrimaryButton";
import InputPassword from "@/components/Input/InputPasswprd";
import { useMutation } from "@tanstack/react-query";
import { useAuthServices } from "@/services/auth";
import Seo from "@/components/Seo";
import Label from "@/components/Label/Label";
import PhoneInput from "react-phone-input-2";

const Login = () => {
  const [loginType, setLoginType] = useState("personal");
  const type = ["personal", "corporate"];
  const { authLogin } = useAuthServices();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<TLogin>({
    mode: "onBlur",
    resolver: zodResolver(LoginSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TLogin> = async (data) => {
    mutate(data);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: authLogin,
    onSuccess: () => {
      navigate(APP_ROUTES.DASHBOARD);
    },
  });

  return (
    <section className="w-[342px]">
      <Seo title="Point2 | Login" />
      <h1 className="text-gray-700 text-[30px] font-medium">
        Login to Account
      </h1>
      <div className="flex items-center gap-1 mt-2">
        {type.map((t, idx) => (
          <Fragment key={idx}>
            <div
              onClick={() => setLoginType(t)}
              className={`${
                t === loginType ? "bg-brand-600" : "bg-transparent"
              } py-2 px-4 rounded-full cursor-pointer`}
            >
              <div className="flex items-center gap-1">
                {t === "personal" ? (
                  <IoMdPerson
                    className={`${
                      t === loginType ? "text-white" : "text-gray-400"
                    }`}
                    size={24}
                  />
                ) : (
                  <IoBagOutline
                    className={`${
                      t === loginType ? "text-white" : "text-gray-400"
                    }`}
                    size={20}
                  />
                )}
                <p
                  className={`${
                    t === loginType ? "text-white" : "text-gray-400"
                  } text-sm capitalize`}
                >
                  {t}
                </p>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      <Link to={APP_ROUTES.CREATE_ACCOUNT}>
        <p className="text-gray-400 text-sm font-medium mt-4">
          Don’t have an account? <span className="font-bold">Register</span>
        </p>
      </Link>
      <form
        className="mt-20 flex flex-col gap-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Label label="Phone Number" isRequired />
          <PhoneInput
            placeholder="90722245789"
            country={"ng"}
            onChange={(value) => setValue("phone", value)}
            inputStyle={{
              width: "100%",
              borderRadius: "8px",
              fontSize: "14px",
            }}
          />
          {errors.phone?.message && (
            <small className="px-3 text-red-500">{errors.phone?.message}</small>
          )}
        </div>
        <InputPassword
          isRequired
          label="Password"
          placeholder="*******"
          {...register("password")}
          error={errors.password?.message}
        />
        <div className="mt-10">
          <PrimaryButton
            text="Log in"
            variant="primary"
            className="w-full"
            isLoading={isPending}
            disabled={isPending}
          />
        </div>
        <Link to={APP_ROUTES.FORGOT_PASSWORD}>
          <p className="text-gray-700 text-base font-medium text-center mt-2">
            Forgot Password?{" "}
          </p>
        </Link>
      </form>
    </section>
  );
};

export default Login;
