import BreadCrumb from "../BreadCrumb";
import { IoIosArrowDown } from "react-icons/io";

interface TNavProps {
  title: string;
}

const DashboardNav = (props: TNavProps) => {
  return (
    <div className="h-[75px] w-full px-8 flex items-center justify-between bg-gray-25 sticky top-0 left-0 right-0 z-10">
      <BreadCrumb title={props?.title} />
      <div className="flex items-center gap-2">
        <div className="bg-gray-100 py-1 px-4 rounded-full text-gray-700 text-sm">
          <p>Personal</p>
        </div>
        <div className="bg-brand-50 h-10 w-10 rounded-full text-brand-600 text-base font-medium flex items-center justify-center">
          <p>oo</p>
        </div>
        <IoIosArrowDown size={16} />
      </div>
    </div>
  );
};

export default DashboardNav;
