import { Toast } from "@/config/toast";

export const lowerCaseRegex = /(?=.*[a-z])\w+/;
export const upperCaseRegex = /(?=.*[A-Z])\w+/;
export const numberRegex = /\d/;
export const specialCharcterRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const checkRegex = (str: string, regex: RegExp) => {
  return regex.test(str);
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  Toast.success("Copied to clipboard!");
};
