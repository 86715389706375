import { ApiRequest } from "../ApiNetwork";
import { OrdersData } from "@/models/Order";

export const useGetOrders = () => {
  const { request } = ApiRequest();

  const getOrders = async (page = 1, query?: string) => {
    try {
        console.log(query);
        
      const url =
        query === "ALL"
          ? `/user/orders/getorders?page=${page}`
          : `/user/orders/getorders?status=${query}&page=${page}`;
      const response = await request("GET", {
        url,
        ignoreError: true,
      });

      if (response.status === "success") {
        // dispatch(setUserOrders(response?.data?.data?.orders?.data));
        return response.data?.data as OrdersData;
      }
    } catch (error) {}
  };

  return {
    getOrders,
  };
};
