import Quote from "@/assets/svgs/quote.svg";

const VisionMission = () => {
  return (
    <section className="mt-24 flex flex-col items-center">
      <div className="width-container">
        <div className="flex flex-col gap-y-10 md:flex-row justify-between md:items-center">
          <div className="max-w-[387px] md:max-w-[410px]">
            <div className="bg-brand-25 py-2 px-3 rounded-full w-fit font-bold text-sm text-gray-700">
              OUR VISION
            </div>
            <p className="text-gray-900 text-[24px] md:text-[30px] font-medium leading-[38px] mt-3">
              To be the leading package logistics platform.
            </p>
          </div>
          <div className="max-w-[84px] md:max-w-[210px]">
            <img src={Quote} alt="" />
          </div>
          <div className="max-w-[396px] md:max-w-[602px] md:ml-10">
            <div className="bg-brand-25 py-2 px-3 rounded-full w-fit font-bold text-sm text-gray-700">
              OUR MISSION
            </div>
            <p className="text-gray-900 text-[24px] md:text-[30px] font-medium leading-[38px] mt-3">
              We are dedicated to exceeding customer expectations with fast
              package delivery.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionMission;
