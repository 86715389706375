import React from "react";
import { IoAddCircleOutline } from "react-icons/io5";
import { RiHome5Line, RiSettingsLine } from "react-icons/ri";
import { CiBellOn } from "react-icons/ci";
import { GoPerson } from "react-icons/go";
import { NavLink, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@/constants/app-routes";
import { FiClock, FiLogOut } from "react-icons/fi";
import Divider from "@/components/Divider";
import Avi from "@/assets/images/supervisor.png";

const Sidebar = () => {
  const userRole = "corp";
  const navigate = useNavigate();
  const topMenu: { icon: React.ReactNode; title: string; path: string }[] = [
    {
      icon: <RiHome5Line />,
      title: "Home",
      path: APP_ROUTES.DASHBOARD,
    },
    {
      icon: <IoAddCircleOutline />,
      title: "Request Rider",
      path: APP_ROUTES.REQUEST_RIDER,
    },
    {
      icon: <FiClock size={20} />,
      title: "Activity",
      path: APP_ROUTES.ACTIVITY,
    },
  ];

  const bottomMenu: { icon: React.ReactNode; title: string; path: string }[] = [
    {
      icon: <CiBellOn />,
      title: "Notification",
      path: APP_ROUTES.NOTIFICATIONS,
    },
    userRole === "corp"
      ? {
          icon: <RiSettingsLine />,
          title: "More",
          path: APP_ROUTES.MORE,
        }
      : {
          icon: <GoPerson />,
          title: "My Profile",
          path: APP_ROUTES.PROFILE,
        },
  ];

  return (
    <div className="h-screen flex flex-col border-r border-r-gray-200 sticky top-0 w-[20%] py-5">
      <div className="flex-grow overflow-y-auto px7 flex flex-col">
        <div
          className="px-7 cursor-pointer"
          onClick={() => navigate(APP_ROUTES.HOME)}
        >
          <h2 className="text-brand-600 text-[36px] font-bold text-center mt-4">
            Point2
          </h2>
        </div>
        <div className="mt-16 space-y-3 px-7">
          {topMenu.map((menu) => (
            <NavLink
              to={menu.path}
              key={menu.title}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-3 bg-gray-50 border-r-2 border-r-brand-600 py-4 pl-4 pr-[56px] w-fit font-bold"
                  : "py-4 pl-4 pr-[56px] flex items-center gap-3"
              }
            >
              <div className="text-2xl text-gray-600">{menu.icon}</div>
              <span className="text-gray-700 text-base">{menu.title}</span>
            </NavLink>
          ))}
        </div>

        {/* Spacer to push bottom menu to the bottom */}
        <div className="flex-grow"></div>

        <div className="space-y-3 px-2">
          {bottomMenu.map((menu) => (
            <NavLink
              to={menu.path}
              key={menu.title}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-3 bg-gray-50 border-r-2 border-r-brand-600 py-4 pl-7 pr-[56px] w-full font-bold"
                  : "py-4 pl-4 pr-[56px] flex items-center gap-3"
              }
            >
              <div className="text-2xl text-gray-600">{menu.icon}</div>
              <span className="text-gray-700 text-base">{menu.title}</span>
            </NavLink>
          ))}
        </div>
      </div>
      <div className="w-full py-4 px-2">
        <Divider />
        <div className="flex items-cente justify-between px-1 mt-5">
          <div className="flex items-center gap-3">
            <img
              src={Avi}
              alt="profile picture"
              className="w-[40px] h-[40px] rounded-full object-cover"
            />
            <div>
              <p className="text-[#344054] text-sm font-medium">
                Owolu Opeyemi
              </p>
              <p className="text-[#7A7A7A] text-sm">email@email.com</p>
            </div>
          </div>
          <div>
            <FiLogOut color="#A3A3A3" size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
