import { z } from "zod";
import { email, phone_number } from "./contact";

export const EditProfileSchema = z.object({
  phone: phone_number,
  email,
  first_name: z
    .string({ required_error: "First name is required." })
    .min(3, { message: "First name must be longer than 3 characters" }),
  last_name: z
    .string({ required_error: "First name is required." })
    .min(3, { message: "First name must be longer than 3 characters" }),
});

export type TEditProfile = z.infer<typeof EditProfileSchema>;
