import { APP_ROUTES } from "@/constants/app-routes";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Analytics = () => {
  return (
    <div className="mt-7">
      <p className="text-gray-700 text-base">Analytics</p>
      <div className="mt-5 flex gap-6">
        <Button
          count={34}
          title="Total Orders"
          tag="This month"
          otherInfo={false}
        />
        <Button
          count={"N3,400,000.00"}
          title="Amount Spent"
          tag="This month"
          otherInfo={false}
        />
        <Button
          count={"N30,000.00"}
          title="Upcoming Payment"
          tag="Due in 5days"
          tagBg="#FF9500"
          otherInfo
          otherInfoTitle="Make Payment"
          infoLink={APP_ROUTES.INVOICE}
        />
      </div>
    </div>
  );
};

const Button = ({
  count,
  title,
  tag,
  tagBg,
  otherInfo,
  otherInfoTitle,
  infoLink,
}: {
  count: number | string;
  title: string;
  tag: string;
  tagBg?: string;
  otherInfo?: boolean;
  otherInfoTitle?: string;
  infoLink?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        "bg-gray-50 p4 rounded-lg flex flex-col gap4 w-full border border-gray-100"
      }
    >
      <p className="text-gray-500 text-[24px] font-medium px-2 py-2">{count}</p>
      <div className="flex items-center gap-2 px-2">
        <p className="text-gray-500 font-medium text-sm">{title}</p>
        <div
          style={{ background: tagBg }}
          className="bg-green-primary p-1 rounded-full text-white text-[10px] font-medium"
        >
          {tag}
        </div>
      </div>
      {otherInfo && (
        <div
          className="bg-brand-600 p-2 rounded-b-lg flex justify-between items-center mt-1 cursor-pointer"
          onClick={() => navigate(infoLink ?? "")}
        >
          <p className="text-[12px] font-bold text-white">{otherInfoTitle}</p>
          <MdKeyboardArrowRight className="text-white" />
        </div>
      )}
    </div>
  );
};

export default Analytics;
