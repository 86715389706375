import React, { useState, InputHTMLAttributes, forwardRef } from "react";
import Label from "../Label/Label";
import { LuEye, LuEyeOff } from "react-icons/lu";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  error?: string | boolean | string[];
  label?: string;
  isRequired?: boolean;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
}

const InputPassword = forwardRef<HTMLInputElement, InputProps>(
  ({ label, type, isRequired, preIcon, postIcon, error, ...rest }, ref) => {
    const [show, setShow] = useState(false);

    return (
      <div className="w-full my-2 relative">
        <Label label={label ?? ""} isRequired={isRequired} />

        <input
          type={show ? "text" : "password"}
          ref={ref as React.Ref<HTMLInputElement>}
          {...rest}
          className={`w-full pl-4 ${postIcon ? "pr-10" : "pr-4"}
          py-[10px] border ${
            !error ? "border-[#D0D5DD]" : "border-red-500"
          } rounded-lg focus:outline-none focus:border-brand-600 text-gray400 text-sm`}
        />

        <div className="absolute top-[35px] right-[12px] flex items-center justify-start">
          <div className="ml-1 cursor-pointer" onClick={() => setShow(!show)}>
            {show ? (
              <LuEyeOff size={20} className="text-gray-500" />
            ) : (
              <LuEye size={20} className="text-gray-500" />
            )}
          </div>
        </div>

        {error && <small className="px-3 text-red-500">{error}</small>}
      </div>
    );
  }
);

export default InputPassword;
