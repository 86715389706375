import { IoLocationOutline } from "react-icons/io5";

const UserLocation = () => {
  return (
    <div className="flex gap-3">
      <div className="bg-brand-25 rounded-lg h-[48px] w-[48px] flex items-center justify-center">
        <IoLocationOutline className="text-brand-600" size={26} />
      </div>
      <div>
        <p className="text-gray-600 text-sm font-medium">My Location</p>
        <p className="text-gray-900 font-bold text-base">
          42, Abule egba Street, Lagos{" "}
        </p>
      </div>
    </div>
  );
};

export default UserLocation;
