import React, { forwardRef, InputHTMLAttributes } from "react";
import PrimaryButton from "../Button/PrimaryButton";
import { FiSearch } from "react-icons/fi";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  buttonText: string;
  onButtonClick: () => void;
  bg?: string;
  buttonBg?: string;
  isButtonIcon?: boolean;
  variant?: "primary" | "filled" | "transparent" | "secondary" | "light";
}

const InputWithButton = forwardRef<HTMLInputElement, IProps>(
  (
    {
      placeholder,
      buttonText,
      onButtonClick,
      buttonBg,
      isButtonIcon = true,
      bg = "bg-white",
      variant = "primary",
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={`flex items-center py-2 px-2 ${bg} rounded-lg overflow-hidden`}
      >
        <input
          ref={ref as React.Ref<HTMLInputElement>}
          type="text"
          placeholder={placeholder}
          className="flex-1 px-4 py-2 focus:outline-none placeholder:text-gray-400 text-sm bg-transparent"
          {...props}
        />
        <PrimaryButton
          postIcon={isButtonIcon && <FiSearch size={18} />}
          variant={variant}
          text={buttonText}
          className="text-sm font-bold"
          onClick={onButtonClick}
          bg={buttonBg}
        />
      </div>
    );
  }
);

export default InputWithButton;
