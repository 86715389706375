import Gift from "@/assets/svgs/giftPackage.svg";
import PrimaryButton from "@/components/Button/PrimaryButton";
import { APP_ROUTES } from "@/constants/app-routes";
import { PiPaperPlaneTiltBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const SendPackage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-[208px] bg-[#0077B6] rounded-lg pl-4 relative mt-4">
      <div className="py-5">
        <p className="text-brand-25 text-xl font-bold w-[149px]">
          Send a Package
        </p>
        <PrimaryButton
          text="Request Pickup"
          variant="light"
          postIcon={<PiPaperPlaneTiltBold />}
          className="font-bold text-[12px] bg-brand-25 mt-3"
          onClick={() => navigate(APP_ROUTES.REQUEST_RIDER)}
        />
      </div>

      <div className="absolute right-0 bottom-0">
        <img src={Gift} alt="" className="h-[120px]" />
      </div>
    </div>
  );
};

export default SendPackage;
