const Hero = () => {
  return (
    <section className="flex flex-col items-center">
      <div className="width-container mt-20">
        <div className="flex flex-col items-center">
          <h1 className="text-gray-900 text-[36px] md:text-[56px] font-bold md:text-center max-w-[395px] md:max-w-[600px]">
            Making Package Delivery Expectational
          </h1>
        </div>
        <p className="font-medium text-sm text-[#475467] md:text-center mt-6">
          Everyone has a story, This is ours. Get to know our team{" "}
        </p>
      </div>
    </section>
  );
};

export default Hero;
