import { Link, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { RegisterOneSchema, TRegisterOne } from "@/types/validations/auth";
import Input from "@/components/Input/Input";
import { MdOutlineMail } from "react-icons/md";
import Label from "@/components/Label/Label";
import PhoneInput from "react-phone-input-2";
import InputPassword from "@/components/Input/InputPasswprd";
import PrimaryButton from "@/components/Button/PrimaryButton";
import { APP_ROUTES } from "@/constants/app-routes";
import Seo from "@/components/Seo";

const RegisterOne = () => {
  const navigate = useNavigate();
  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<TRegisterOne>({
    mode: "onBlur",
    resolver: zodResolver(RegisterOneSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TRegisterOne> = async (data) => {
    console.log(data);
    navigate(APP_ROUTES.CREATE_ACCOUNT2, { state: data });
  };

  return (
    <section className="w-[450px]">
      <Seo title="Point2 | Register" />
      <h1 className="text-gray-700 text-[30px] font-medium">
        Register an account
      </h1>
      <p className="text-gray-400 text-sm font-medium mt-3">Personal details</p>
      <form
        className="mt-8 flex flex-col gap1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-[402px] flex flex-col gap-1">
            <div>
              <Label label="Phone Number" isRequired />
              <PhoneInput
                placeholder="90722245789"
                country={"ng"}
                onChange={(value) => setValue("phone_number", value)}
                inputStyle={{
                  width: "100%",
                  borderRadius: "8px",
                  fontSize: "14px",
                }}
              />
              {errors.phone_number?.message && (
                <small className="px-3 text-red-500">
                  {errors.phone_number?.message}
                </small>
              )}
            </div>
            <Input
              preIcon={<MdOutlineMail size={20} className="text-gray-500" />}
              isRequired
              label="Email Address"
              placeholder="example@mail.com"
              {...register("email")}
              error={errors.email?.message}
            />
            <Input
              isRequired
              label="First Name"
              placeholder="First name"
              {...register("first_name")}
              error={errors.first_name?.message}
            />
            <Input
              isRequired
              label="Last Name"
              placeholder="Last name"
              {...register("last_name")}
              error={errors.last_name?.message}
            />
            <InputPassword
              isRequired
              label="Password"
              placeholder="*******"
              {...register("password")}
              error={errors.password?.message}
            />
            <InputPassword
              isRequired
              label="Confirm Password"
              placeholder="*******"
              {...register("confirm_password")}
              error={errors.confirm_password?.message}
            />
            <div className="mt-1">
              <PrimaryButton
                text="Next (1/2)"
                variant="primary"
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-7">
          <Link to={APP_ROUTES.LOGIN}>
            <p className="font-medium text-gray-700 text-base">
              Already Registered? Login
            </p>
          </Link>
          <Link to={APP_ROUTES.FORGOT_PASSWORD}>
            <p className="font-medium text-gray-700 text-base">
              Forgot Password?
            </p>
          </Link>
        </div>
      </form>
    </section>
  );
};

export default RegisterOne;
