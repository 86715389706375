import PrimaryButton from "@/components/Button/PrimaryButton";
import InputPassword from "@/components/Input/InputPasswprd";
import Seo from "@/components/Seo";
import { APP_ROUTES } from "@/constants/app-routes";
import { useAuthServices } from "@/services/auth";
import { ResetPasswordSchema, TResetPassword } from "@/types/validations/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { authResetPassword } = useAuthServices();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<TResetPassword>({
    mode: "onBlur",
    resolver: zodResolver(ResetPasswordSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TResetPassword> = async (data) => {
    mutate(data);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: authResetPassword,
  });

  return (
    <section className="w-[425px]">
      <Seo title="Point2 | Reset password" />
      <h1 className="text-gray-700 text-[30px] font-medium">
        Set New Password
      </h1>
      <p className="text-gray-900 text-sm font-medium mt-1">
        Enter new password to access your account
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-10 flex flex-col gap-2"
        action=""
      >
        <InputPassword
          isRequired
          label="Enter New Password"
          placeholder="enter your password"
          {...register("password")}
          error={errors.password?.message}
        />
        <InputPassword
          isRequired
          label="Confirm New Password"
          placeholder="enter your password"
          {...register("confirm_password")}
          error={errors.confirm_password?.message}
        />
        <div className="mt-10">
          <PrimaryButton
            isLoading={isPending}
            disabled={isPending}
            text="Reset Password"
            variant="primary"
            className="w-full"
          />
        </div>
        <div
          onClick={() => navigate(APP_ROUTES.LOGIN)}
          className="text-[#404040] font-medium text-sm text-center mt-5 cursor-pointer"
        >
          Back to Login
        </div>
      </form>
    </section>
  );
};

export default ResetPassword;
