import Image1 from "@/assets/images/order-steps1.png";
import Image2 from "@/assets/images/order-steps2.png";
import Image3 from "@/assets/images/order-steps3.png";

const OrderSteps = () => {
  return (
    <section className="mt-10 flex flex-col items-center">
      <h1 className="max-w-[356px] text-[30px] lg:text-[36px] font-bold text-center text-gray-800">
        Order a Dispatch Rider in 3 easy steps
      </h1>
      <div className="w-full max-w-[1182px] flex flex-col lg:flex-row items-center gap-4 mt-10">
        <Steps
          width="218px"
          number={1}
          image={Image1}
          title="Request a Dispatch Rider"
          subtitle="Book a rider in a few clicks, and we'll handle it fast"
        />
        <Steps
          width="266px"
          number={2}
          image={Image2}
          title="Rider picks up Package"
          subtitle="Our rider picks up your package quickly and safely, ready for delivery"
        />
        <Steps
          width="218px"
          number={3}
          image={Image3}
          title="Receive your Package"
          subtitle="Your package is on the way! Get it delivered securely and on time"
        />
      </div>
    </section>
  );
};

const Steps = ({
  image,
  title,
  subtitle,
  number,
  width,
}: {
  image: string;
  title: string;
  subtitle: string;
  number: number;
  width: string;
}) => (
  <div className="w-full max-w-[394px]">
    <div className="flex flex-row bg-gray-25 rounded-2xl px-4 gap-3">
      <p className="text-brand-100 text-[80px] font-black">{number}</p>
      <img src={image} alt="" className="w-[207px] mt-10" />
    </div>
    <div className="mt-4">
      <h2 className="text-gray-700 text-xl font-bold text-center">{title}</h2>
      <div className="flex flex-col items-center">
        <p
          className={`text-gray-700 text-sm font-normal text-center`}
          style={{ maxWidth: width }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

export default OrderSteps;
