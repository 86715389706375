import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

type ScreenContextReturnType = {
  handleTab: ({ tab }: { tab: string }) => void;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  setOrderId: Dispatch<SetStateAction<string>>;
  orderId: string;
};

const ScreenContext = createContext<ScreenContextReturnType | null>(null);

const ScreenContextProvider = ({ children }: any) => {
  const [selectedTab, setSelectedTab] = useState("ALL");
  const [orderId, setOrderId] = useState<string>("");

  const handleTab = ({ tab }: { tab: string }) => {
    setSelectedTab(tab);
  };

  return (
    <ScreenContext.Provider
      value={{
        handleTab,
        selectedTab,
        setSelectedTab,
        orderId,
        setOrderId,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreenContext = () =>
  useContext(ScreenContext) as ScreenContextReturnType;

export default ScreenContextProvider;
