import Accordion from "@/components/Accordion/Accordion";
import PrimaryButton from "@/components/Button/PrimaryButton";
import InputWithButton from "@/components/Input/InputWithButton";
import SideModal from "@/components/Modal/SideModal";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useState } from "react";
import { CiCalendar } from "react-icons/ci";

const PaymentMethod = ({ onSuccessOpen }: { onSuccessOpen: () => void }) => {
  const userRole = "corp";
  const {
    isOpen: charges,
    onClose: onChargesClose,
    onOpen: onChargesOpen,
  } = useDisclosure();
  const [paymentType, setPaymentType] = useState("pay with transfer");
  const [payeeType, setPayeeType] = useState("sender pay");
  const payType = ["pay with transfer", "card payment"];
  const payee = ["sender pay", "receiver pay"];

  const [accordionIdx, setAccordionIdx] = useState<number[]>([]);

  const toggleAccordion = (index: number) => {
    setAccordionIdx((prevState) =>
      prevState.includes(index)
        ? prevState.filter((i) => i !== index)
        : [...prevState, index]
    );
  };

  const orderDetails = [{ 0: "" }, { 1: "" }];

  return (
    <>
      <div>
        {userRole !== "corp" && (
          <>
            <p className="text-gray-700 font-medium text-[18px]">
              Payment Method
            </p>
            <div className="flex flex-col gap-5 mt-3">
              {payType.map((del, idx) => (
                <div
                  onClick={() => setPaymentType(del)}
                  key={idx}
                  className="flex items-center justify-between mt-2"
                >
                  <p className="text-gray-700 text-sm font-medium capitalize">
                    {del}
                  </p>
                  <div
                    className={`h-5 w-5 rounded-full ${
                      paymentType === del
                        ? "border-2 border-brand-600 bg-brand-25"
                        : "bg-[#D9D9D9]"
                    }`}
                  ></div>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-2 mt-3 bg-gray-50 px-4 py-2 rounded-lg">
              {payee.map((del, idx) => (
                <div
                  onClick={() => setPayeeType(del)}
                  key={idx}
                  className="flex items-center justify-between mt-2"
                >
                  <p className="text-gray-700 text-sm font-medium capitalize">
                    {del}
                  </p>
                  <div
                    className={`h-5 w-5 rounded-full ${
                      payeeType === del
                        ? "border-2 border-brand-600 bg-brand-25"
                        : "bg-[#D9D9D9]"
                    }`}
                  ></div>
                </div>
              ))}
            </div>
          </>
        )}
        {userRole === "corp" && (
          <>
            <p className="text-gray-700 font-medium text-[18px]">
              Delivery Summary
            </p>
            <div className="mt-3 rounded bg-[#f7f4ff] p-3 flex justify-between items-center">
              <p className="text-gray-600 font-medium text-sm">
                Scheduled payment
              </p>
              <div className="flex gap-2 items-center">
                <CiCalendar size={24} className="text-gray-500" />
                <p className="text-gray-600 font-medium text-sm">
                  March 7, 2024
                </p>
              </div>
            </div>
            <p className="text-gray-400 text-xs font-medium mt-3">
              Your payment will be due on the date scheduled above
            </p>
          </>
        )}

        <div className="mt-5">
          <p className="text-gray-500 text-xs font-medium">
            Total Deliveries ({orderDetails?.length})
          </p>
          <div className="mt-3 flex flex-col gap-5">
            {orderDetails.map((order, idx) => (
              <div className="bg-brand-25 px-3 rounded">
                <Accordion
                  isBorderBotton={false}
                  titleStyle={"text-gray-600 text-xs"}
                  key={idx}
                  title={"Idris Emma"}
                  isCollapsed={accordionIdx.includes(idx)}
                  toggleSection={() => toggleAccordion(idx)}
                  style={{ paddingTop: "12px" }}
                >
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center justify-between">
                      <p className="text-gray-600 text-xs">Discount </p>
                      <p className="text-gray-600 text-sm font-medium">
                        N100.00
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-gray-600 text-xs">VAT (7.5%)</p>
                      <p className="text-gray-600 text-sm font-medium">
                        N100.00
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-gray-600 text-xs">Delivery Type</p>
                      <p className="text-brand-600 text-sm font-medium">
                        Standard
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-gray-600 text-xs">Delivery fee</p>
                      <p className="text-gray-600 text-sm font-medium">
                        N1500.00
                      </p>
                    </div>
                  </div>
                </Accordion>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-6">
          <InputWithButton
            placeholder="Apply Coupon Code"
            buttonText="Apply"
            onButtonClick={() => {}}
            variant="secondary"
            buttonBg="bg-brand-900"
            isButtonIcon={false}
            bg="bg-gray-50"
          />
        </div>
        <div className="mt-8">
          <PrimaryButton
            variant="primary"
            text="Get Charges"
            className="w-full"
            onClick={onChargesOpen}
            disabled={charges}
          />
        </div>
        {charges && (
          <div className="mt-6">
            <div className="space-y-3">
              {/* <div className="flex justify-between">
                <p className="text-gray-600 font-medium text-base">
                  Delivery fee
                </p>
                <p className="text-gray-600 font-medium text-base">15000</p>
              </div> */}
              {/* <div className="flex justify-between">
                <p className="text-gray-600 font-medium text-base">
                  Vat (7.5%)
                </p>
                <p className="text-gray-600 font-medium text-base">100</p>
              </div> */}
              <div className="bg-gray-50 p-4 flex flex-col justify-center items-center">
                <p className="text-gray-600 text-base font-medium">TOTAL</p>
                <p className="text-gray-800 font-bold text-lg">
                  N1,600 - N4,000.00
                </p>
              </div>
            </div>
            <div className="mt-7">
              <PrimaryButton
                variant="primary"
                text="Confirm Order"
                className="w-full"
                onClick={onSuccessOpen}
              />
            </div>
            <a
              href="https://point2.ng/terms-conditions"
              target="_blank"
              className=""
            >
              <p className="text-gray-400 font-medium text-xs text-center cursor-pointer mt-8">
                By continuing, you agree to Point2{" "}
                <span className="text-gray-700">Terms & Condition</span> and my
                package align with Point 2 Guidelines
              </p>
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentMethod;
