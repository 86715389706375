import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "@/components/ScrollToTop";
import { Toaster } from "react-hot-toast";
import GlobalContextProvider from "./stores/context/GlobalContext";
import { Provider } from "react-redux";
import { store } from "./stores/store";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/gateway";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <BrowserRouter>
          <HelmetProvider>
            <Provider store={store}>
              <App />
            </Provider>
            <ScrollToTop />
          </HelmetProvider>
        </BrowserRouter>
      </GlobalContextProvider>
      <Toaster position="top-center" reverseOrder={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
