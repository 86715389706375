const Label = ({
  label,
  isRequired,
}: {
  label: string;
  isRequired?: boolean;
}) => {
  return (
    <div className="flex flex-row items-center">
      <p className="text-sm font-medium text-gray-600">{label}</p>
      {isRequired && <span className="text-brand-600">*</span>}
    </div>
  );
};

export default Label;
