import { z } from "zod";
import { email, phone_number } from "./contact";

export const password = z
  .string({ required_error: "Password is required." })
  .min(8, { message: "Password must be at least 8 characters long." });

export const confirm_password = z
  .string()
  .trim()
  .min(1, { message: "Confirm password is required." });

export const LoginSchema = z.object({
  // email,
  phone: phone_number,
  password,
});

export const RegisterOneSchema = z.object({
  phone_number,
  email,
  first_name: z
    .string({ required_error: "First name is required." })
    .min(3, { message: "First name must be longer than 3 characters" }),
  last_name: z
    .string({ required_error: "First name is required." })
    .min(3, { message: "First name must be longer than 3 characters" }),
  password,
  confirm_password,
});

export const RegisterTwoSchema = z.object({
  company_name: z.string({ required_error: "Company name is required." }),
  company_address: z.string({ required_error: "Company address is required" }),
  name_of_contact: z
    .string({ required_error: "Name of contact is required." })
    .min(3, { message: "Name of contact must be longer than 3 characters" }),
  company_email: email,
  contact_phone_number: phone_number,
});

export const CompleteRegisterSchema =
  RegisterOneSchema.merge(RegisterTwoSchema);

export const ResetPasswordSchema = z.object({
  password,
  confirm_password,
});

export const ForgotPasswordSchema = z.object({
  email,
});

export type TLogin = z.infer<typeof LoginSchema>;
export type TRegisterOne = z.infer<typeof RegisterOneSchema>;
export type TRegisterTwo = z.infer<typeof RegisterTwoSchema>;
export type CompleteRegisterPayload = z.infer<typeof CompleteRegisterSchema>;
export type TResetPassword = z.infer<typeof ResetPasswordSchema>;
export type TForgotPassword = z.infer<typeof ForgotPasswordSchema>;
