import PrimaryButton from "@/components/Button/PrimaryButton";
import Input from "@/components/Input/Input";
import { InputSelect } from "@/components/Input/InputSelect";
import Label from "@/components/Label/Label";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoMdAddCircleOutline } from "react-icons/io";
import { LuMinusCircle } from "react-icons/lu";
import PhoneInput from "react-phone-input-2";

const Request = () => {
  const deliveryType = ["Standard Delivery", "Express Delivery"];
  const [deliverySelect, setDeliverySelect] = useState("Standard Delivery");
  const [deliveryLocations, setDeliveryLocations] = useState([{ id: 1 }]);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const handleAddLocation = () => {
    setDeliveryLocations((prevLocations) => [
      ...prevLocations,
      { id: prevLocations.length + 1 },
    ]);
  };

  const handleRemoveLocation = (indexToRemove: number) => {
    setDeliveryLocations((prevLocations) =>
      prevLocations.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-gray-800 font-medium text-base">
            Pickup Location
          </h2>
          <p className="text-gray-800 text-sm">Enter Pickup Details</p>
        </div>
        <div
          onClick={handleAddLocation}
          className="flex items-center gap-4 cursor-pointer"
        >
          <p className="text-sm font-bold text-brand-600">
            Add New Delivery Location
          </p>
          <IoMdAddCircleOutline className="text-brand-600" size={24} />
        </div>
      </div>
      <div className="bg-[#fbfeff] rounded-lg w-full p-5 mt-4 space-y-3 border border-brand-25">
        <div className="flex items-center gap-8">
          <div className="w-[50%]">
            <Input
              name=""
              placeholder="Account owner name auto display"
              label="Customer name"
              isRequired
            />
          </div>
          <div className="w-[50%]">
            <Label label="Phone Number" isRequired />
            <PhoneInput
              placeholder="90722245789"
              country={"ng"}
              //   onChange={(value) => setValue("contact_phone_number", value)}
              inputStyle={{
                width: "100%",
                borderRadius: "8px",
                fontSize: "14px",
              }}
            />
          </div>
        </div>
        <div className="flex items-center gap-8">
          <div className="w-[50%]">
            <div className="flex gap-2">
              <Label label="Pickup address" isRequired />
              <p className="text-brand-600 font-bold text-sm cursor-pointer">
                Use Current Location
              </p>
            </div>
            <div className="w-full relative py-[10px] border border-[#D0D5DD] rounded-lg text-sm px-4 bg-white">
              <p>{"Select Address"}</p>
            </div>
          </div>
          <div className="w-[50%]">
            <Input
              name=""
              label="Landmark or B/stop"
              placeholder="Enter b/stop (optional)"
            />
          </div>
        </div>
      </div>
      {deliveryLocations.map((_, index) => (
        <div className="my-5">
          <h2 className="text-gray-800 font-medium text-base">
            Delivery Location {index + 1}
          </h2>
          <p className="text-gray-800 text-sm">Enter Delivery Details</p>
          <div className="bg-[#fbfeff] border border-brand-25 rounded-lg w-full p-5 mt-4 space-y-3">
            <div className="flex items-center gap-8">
              <div className="w-[50%]">
                <Input
                  name=""
                  placeholder="enter reciever’s name"
                  label="Item name"
                  isRequired
                />
              </div>
              <div className="w-[50%]">
                <Label label="Item Category" />
                <InputSelect
                  choices={[
                    { value: "Abs", label: "abc" },
                    { value: "uhn", label: "aha" },
                  ]}
                  value={getValues("option")}
                  {...register("option")}
                  onChange={(val: string) => setValue("option", val)}
                  placeholder="Select Category"
                />
              </div>
            </div>
            <div className="flex items-center gap-8">
              <div className="w-[50%]">
                <Input
                  name=""
                  placeholder="enter reciever’s name"
                  label="Reciever’s name"
                  isRequired
                />
              </div>
              <div className="w-[50%]">
                <Label label="Phone Number" isRequired />
                <PhoneInput
                  placeholder="90722245789"
                  country={"ng"}
                  //   onChange={(value) => setValue("contact_phone_number", value)}
                  inputStyle={{
                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "14px",
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row-reverse items-center gap-8">
              <div className="w-[50%]">
                <div className="flex gap-2">
                  <Label label="Receiver address" isRequired />
                </div>
                <div className="w-full relative py-[10px] border border-[#D0D5DD] rounded-lg text-sm px-4 bg-white">
                  <p>{"Select Address"}</p>
                </div>
              </div>
              <div className="w-[50%]">
                <Input
                  name=""
                  label="Email Address"
                  placeholder="Enter email address"
                />
              </div>
            </div>
            <div className="flex flex-row-reverse items-center gap-8">
              <div className="w-[50%]">
                <div className="flex gap-2">
                  <Label label="Delivery type" />
                </div>
                <div className="flex gap-8 items-center mt-3">
                  {deliveryType.map((del, idx) => (
                    <div
                      onClick={() => setDeliverySelect(del)}
                      key={idx}
                      className="flex items-center gap-2"
                    >
                      <div
                        className={`h-5 w-5 rounded-full ${
                          deliverySelect === del
                            ? "border-2 border-brand-600 bg-brand-25"
                            : "bg-[#D9D9D9]"
                        }`}
                      ></div>
                      <p className="text-gray-700 text-sm font-medium">{del}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-[50%]">
                <Input
                  name=""
                  label="Landmark or B/stop"
                  placeholder="Enter b/stop (optional)"
                />
              </div>
            </div>
          </div>

          {deliveryLocations.length > 1 && (
            <div
              onClick={() => handleRemoveLocation(index)}
              className="flex items-center gap-4 cursor-pointer justify-end mt-5"
            >
              <p className="text-sm font-bold text-brand-600">
                Remove Location
              </p>
              <LuMinusCircle className="text-brand-600" size={24} />
            </div>
          )}
        </div>
      ))}
      <div className="my-5 flex items-center gap-4">
        <PrimaryButton text="Save" variant="primary" className="w-[140px]" />
        <PrimaryButton
          text="Reset"
          variant="light"
          className="w-[140px] bg-brand-25"
        />
      </div>
    </div>
  );
};

export default Request;
