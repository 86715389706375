import Tabs from "./Tabs";
import EmptyBox from "@/assets/svgs/emptyBox.svg";
import { Orders } from "@/models/Order";
import Box from "@/assets/svgs/box2.svg";
import { GoDotFill } from "react-icons/go";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useScreenContext } from "../context/screenContext";
import { useGetOrders } from "@/services/order";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import Spinner from "@/components/Spinner";

export interface TabsProps {
  title: string;
  key: string;
  count?: string | number;
  countBg?: string;
}

const OrdersList = () => {
  const { setOrderId, orderId, selectedTab } = useScreenContext();
  const [page, setPage] = useState(1);

  const { getOrders } = useGetOrders();

  const { data: orders, isLoading } = useQuery({
    queryKey: ["get_orders", selectedTab, page],
    queryFn: () => getOrders(page, selectedTab),
  });

  const tabs: TabsProps[] = [
    { title: "All", key: "ALL", count: 0, countBg: "#444CE7" },
    { title: "Delivered", key: "DELIVERED", count: 0, countBg: "#27AE60" },
    { title: "In Transit", key: "INTRANSIT", count: 0, countBg: "#F2C94C" },
    { title: "Canceled", key: "CANCELED", count: 0, countBg: "#EB5757" },
  ];

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div>
      <Tabs tabs={tabs} />
      <div className="mt-8">
        <p className="text-gray-600 font-medium text-lg">Orders</p>

        {isLoading && <Spinner />}
        {orders?.orders?.length === 0 && (
          <div className="mt-[60px]">
            <div className="flex items-center justify-center">
              <img src={EmptyBox} alt="" className="w-[150px] h-[150px]" />
            </div>
            <p className="text-gray-800 text-sm text-center">
              Start sending packages <br /> to see activity here
            </p>
          </div>
        )}

        {orders?.orders && orders?.orders?.length > 0 && (
          <div className="flex flex-col gap-4">
            {orders?.orders?.map((order) => (
              <div
                onClick={() => setOrderId(order?.id)}
                className={`${
                  orderId === order?.id ? "border-brand-600" : "border-gray-200"
                } border-[0.5px] rounded-2xl w-full h[138px] mt-3 p-6`}
              >
                <div className="flex justify-between items-center">
                  <div className="flex gap-3">
                    <img src={Box} alt="box" />
                    <div>
                      <p className="text-gray-700 font-bold text-sm">
                        {order?.package_name}
                      </p>
                      <p className="text-gray-800 text-sm font-normal">
                        Tracking ID: {order?.tracking_id}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="text-gray-600 font-bold text-sm">
                      Status: Picked up
                    </p>
                    <FaRegCircleCheck color="#32D583" />
                  </div>
                </div>
                <div className="flex mt-6">
                  <div className="flex-1 flex gap-1">
                    <GoDotFill className="text-brand-100" size={20} />
                    <div>
                      <p className="text-gray-600 text-xs font-medium">From</p>
                      <p className="text-gray-700 text-sm font-medium">
                        {order?.pickup_location}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 flex">
                    <GoDotFill className="text-green-primary" size={20} />
                    <div>
                      <p className="text-gray-600 text-xs font-medium">
                        Shipped to
                      </p>
                      <p className="text-gray-700 text-sm font-medium">
                        {order?.delivery_point_location}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdersList;
