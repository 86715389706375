import Ads from "@/components/ads";
import SendPackage from "@/components/send-package";
import InvoiceDetails from "./component/invoice-details";

const Invoice = () => {
  return (
    <div className="flex w-full min-hscreen">
      <div className="border-r border-r-gray-200 w-[70%] py-5 overflow-y-scroll h-screen px-8">
        <InvoiceDetails />
      </div>
      <div className="w-[30%] min-hscreen sticky top-0 left-0">
        <div className="px-4 h-screen overflow-y-scroll overflow-hidden">
          <SendPackage />
          <Ads />
        </div>
      </div>
    </div>
  );
};

export default Invoice;
