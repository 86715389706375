import { GoDotFill } from "react-icons/go";
import Divider from "./Divider";
import { FaRegCircleCheck } from "react-icons/fa6";
import SideModal from "./Modal/SideModal";
import Box from "@/assets/svgs/box.svg";

interface DetailsProps {
  onClose: () => void;
  trackingId: string;
}

const TrackingDetails = ({ onClose, trackingId }: DetailsProps) => {
  return (
    <SideModal
      onClose={onClose}
      title="Track Package"
      subTitle={`Tracking ID: ${trackingId}`}
      style={{width: "897px"}}
    >
      <div className="my-6">
        <Divider />
        <div className="mt-5">
          <h2 className="text-gray-800 font-bold text-xl">In Transit</h2>
          <p className="text-sm text-gray-800 mt-2">
            Estimated Delivery date: 15 October, 2024
          </p>
          {/* Change this to map later */}
          <div className="flex justify-between items-center mt-7 mb-10">
            <div>
              <div className="w-[182px] h-1 bg-green-primary" />
              <div className="flex items-center gap-2 mt-2">
                <FaRegCircleCheck className="text-green-primary" size={16} />
                <p className="text-gray-600 text-sm font-medium">Order</p>
              </div>
            </div>
            <div>
              <div className="w-[182px] h-1 bg-green-primary" />
              <div className="flex items-center gap-2 mt-2">
                <FaRegCircleCheck className="text-green-primary" size={16} />
                <p className="text-gray-600 text-sm font-medium">Picked up</p>
              </div>
            </div>
            <div>
              <div className="w-[182px] h-1 bg-gray-300 relative">
                <div className="w-[70px] h-1 bg-green-primary absolute top-0 left-0" />
              </div>
              <div className="flex items-center gap-2 mt-2">
                <div className="relative w-4 h-4 rounded-full border-[1.5px] border-gray-300">
                  <div className="absolute top-[-1.5px] right-[-1.5px] w-[9px] h-[9px] border-t-[2px] border-r-[2px] border-blue-500 rounded-tr-full"></div>
                </div>
                <p className="text-gray-600 text-sm font-medium">In Transit</p>
              </div>
            </div>
            <div>
              <div className="w-[182px] h-1 bg-gray-300" />
              <div className="flex items-center gap-2 mt-2">
                <div className="border-[1.5px] border-gray-300 w-4 h-4 rounded-full" />
                <p className="text-gray-600 text-sm font-medium">Delivered</p>
              </div>
            </div>
          </div>
          <Divider />
          <div className="mt-5">
            <p className="text-gray-800 text-sm">Package Details</p>
            <div className="my-7">
              <div className="flex gap-[17px] items-center">
                <div className="bg-brand-25 h-[42px] w-[42px] rounded-full flex justify-center items-center">
                  <img src={Box} alt="box" />
                </div>
                <p className="text-gray-700 font-bold text-sm">
                  Standing Fan ( Black )
                </p>
              </div>
              <div className="flex flex-wrap gap-x-32 gap-y-6 mt-7">
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Recipient Name
                  </p>
                  <p className="font-bold text-sm text-gray-700">
                    Jimoh Adigun Taiwo
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Rider’s Name
                  </p>
                  <p className="font-bold text-sm text-gray-700">Unavailable</p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Reciever
                  </p>
                  <p className="font-bold text-sm text-gray-700">Who to Pay</p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Payment Status
                  </p>
                  <p className="font-bold text-sm text-green-primary">Paid</p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Delivery type
                  </p>
                  <p className="font-bold text-sm text-gray-700">Express</p>
                </div>
                <div>
                  <p className="text-gray-600 text-[12px] font-medium">
                    Amount Payable (N)
                  </p>
                  <p className="font-bold text-sm text-gray-700">5,000.00</p>
                </div>
              </div>
              <div className="bg-gray-50 mt-7 p-4 rounded-2xl flex flex-col gap-10">
                <div className="flex-1 flex gap-1">
                  <GoDotFill className="text-brand-100" size={20} />
                  <div>
                    <p className="text-gray-600 text-xs font-medium">From</p>
                    <p className="text-gray-700 text-sm font-medium">
                      Idumota Store, Orile Agege
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex gap-1">
                  <GoDotFill className="text-green-primary" size={20} />
                  <div>
                    <p className="text-gray-600 text-xs font-medium">
                      Shipped to
                    </p>
                    <p className="text-greay-700 text-sm font-medium">
                      32, Sangodiya Avenue
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SideModal>
  );
};

export default TrackingDetails;
