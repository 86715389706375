import { APP_ROUTES } from "@/constants/app-routes";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardNav from "@/components/DashboardNav";

const DashboardLayout = () => {
  const token = true;
  const navigate = useNavigate();
  const location = useLocation();

  const getTitle = () => {
    const path = location.pathname;

    switch (path) {
      case APP_ROUTES.DASHBOARD:
        return "Home";
      case APP_ROUTES.REQUEST_RIDER:
        return "New Order";
      case APP_ROUTES.ACTIVITY:
        return "Activity";
      case APP_ROUTES.NOTIFICATIONS:
        return "Notifications";
      case APP_ROUTES.PROFILE:
        return "Profile";
      case APP_ROUTES.MORE:
        return "Analytics & More";
      case APP_ROUTES.INVOICE:
        return "Invoice";
      default:
        return "Dashboard";
    }
  };

  useEffect(() => {
    if (!token) {
      navigate(APP_ROUTES.HOME);
    }
  }, [token]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full max-w-screen-2xl flex">
        <Sidebar />
        <div style={{}} className="flex flex-col w-[80%]">
          <DashboardNav title={getTitle()} />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
