import { APP_ROUTES } from "@/constants/app-routes";
import { useGlobalContext } from "@/stores/context/GlobalContext";
import { LiaTimesSolid } from "react-icons/lia";
import { NavLink, useLocation } from "react-router-dom";

const MobileNav = ({ toggle }: { toggle: () => void }) => {
  const { team, contact, scrollToSection } = useGlobalContext();
  const location = useLocation();

  return (
    <nav className="mobile-nav_content text-gray-600 text-xl font-medium">
      <span className="mobile-nav__close" onClick={toggle}>
        <LiaTimesSolid size={24} />
      </span>
      <div className="mobile-nav_container mt-12">
        <ul className="" onClick={toggle}>
          <NavLink to={APP_ROUTES.HOME}>
            <li className="py-6">Home</li>
          </NavLink>
          <NavLink to={APP_ROUTES.ABOUT}>
            <li className="py-6">About us</li>
          </NavLink>
          {location.pathname === APP_ROUTES.ABOUT && (
            <li onClick={() => scrollToSection(team)} className="py-6">
              Team
            </li>
          )}
          <li onClick={() => scrollToSection(contact)} className="py-6">
            Contact us
          </li>
          {/* <NavLink to={APP_ROUTES.LOGIN}>
            <li className="py-6">Login</li>
          </NavLink> */}
        </ul>
      </div>
      {/* <div className="absolute bottom-5">
        <div className="w-full" onClick={toggle}>
          <PrimaryButton
            path="/contact-sales"
            title="Contact Sales"
            transparent={false}
          />
        </div>
      </div> */}
    </nav>
  );
};

export default MobileNav;
