import "./App.css";
import Layout from "@/layouts/AppLayout";
import { Route, Routes } from "react-router-dom";
import { authRoutes, privateRoute, routes } from "@/utils/routes";
import AuthLayout from "@/layouts/AuthLayout";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/stores/store";
import { Toast } from "@/config/toast";
import DashboardLayout from "@/layouts/DashboardLayout";

function App() {
  const { errors, successMessages } = useSelector(
    (state: RootState) => state.app
  );
  // const Element = {
  //   [APP_ROUTES.HOME]: <Home />,
  //   [APP_ROUTES.ABOUT]: <About />,
  // };

  useEffect(() => {
    if (errors.length > 0) {
      Toast.error(errors.join("\n"));
    }
  }, [errors]);

  useEffect(() => {
    if (successMessages.length > 0) {
      Toast.success(successMessages.join("\n"));
    }
  }, [successMessages]);

  return (
    <Routes>
      <Route element={<Layout />}>
        {routes?.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>

      <Route element={<AuthLayout />}>
        {authRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>

      <Route element={<DashboardLayout />}>
        {privateRoute.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
