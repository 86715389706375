import { siteURL } from "@/config";
import { Helmet, Attributes } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export default function Seo(props: Attributes) {
  const location = useLocation();

  const meta: Record<string, string> = {
    title: "Point2 Delivery",
    description: "Point2 Delivery | Anywhere, Anytime",
    image: "/meta_image.png",
    type: "website",
    ...props,
  };

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="robots" content="follow, index" />
      <meta
        name="keywords"
        content="Point2 Delivery, market intelligence, delivery, logistics, B2B, order, order and deliver, goods location, location-based, rider tracking"
      />
      <meta name="author" content="Point2 Delivery" />
      <meta name="description" content={meta.description} />
      <meta property="og:url" content={`${siteURL}${location.pathname}`} />
      <link rel="canonical" href={`${siteURL}${location.pathname}`} />
      <meta property="og:type" content={meta.type} />
      <meta property="og:description" content={meta.description} />
      <meta
        property="og:image"
        // content={meta.image}
        content={`${siteURL}${meta.image}`}
      />
      {/* <meta property="og:image:width" content="344" />
      <meta property="og:image:height" content="72" /> */}
      <meta property="og:image:type" content="image/png" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:site_name" content="Point2 Delivery" />
      {/* <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Point2 Delivery" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} /> */}
    </Helmet>
  );
}
