// import Contact from "./components/contact";
import Contact from "@/components/Contact";
import DownloadApp from "./components/download-app";
import Faqs from "./components/Faqs";
import Hero from "./components/Hero";
import OrderSteps from "./components/order-steps";
import WhoCanUse from "./components/who-can-use";
import Seo from "@/components/Seo";

const Home = () => {
  return (
    <div className="flex-1">
      <Seo />
      <Hero />
      <OrderSteps />
      <WhoCanUse />
      <Contact />
      <Faqs />
      <DownloadApp />
    </div>
  );
};

export default Home;
