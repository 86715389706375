import Ads from "@/components/ads";
import SendPackage from "@/components/send-package";
import Analytics from "./component/analytics";
import ProfileTabs from "../profile/component/profile-tabs";
import ProfileHeader from "../profile/component/profile-header";

const More = () => {
  return (
    <div className="flex w-full min-hscreen">
      <div className="border-r border-r-gray-200 w-[70%] py-5 overflow-y-scroll h-screen px-8">
        <ProfileHeader />
        <Analytics />
        <ProfileTabs />
      </div>
      <div className="w-[30%] min-hscreen sticky top-0 left-0">
        <div className="px-4 h-screen overflow-y-scroll overflow-hidden">
          <SendPackage />
          <Ads />
        </div>
      </div>
    </div>
  );
};

export default More;
