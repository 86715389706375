import { ComponentProps } from "react";
import classNames from "classnames";
import { ClipLoader } from "react-spinners";

interface ButtonProps extends ComponentProps<"button"> {
  variant: "filled" | "transparent" | "primary" | "secondary" | "light";
  text: string;
  isLoading?: boolean;
  bg?: string;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  size?: "sm" | "md" | "lg" | "small";
}

const PrimaryButton = ({
  text,
  variant = "primary",
  className,
  isLoading,
  postIcon,
  preIcon,
  bg,
  ...rest
}: ButtonProps) => {
  const variantClassName = {
    primary: "bg-brand-600",
    secondary: bg,
  };

  return (
    <button
      className={classNames(
        `rounded-lg px-7 py-[10px] disabled:opacity-45 ${
          variant === "light"
            ? "text-brand-600"
            : variant === "primary"
            ? "text-white"
            : "text-white"
        } transition duration-300 flex flex-row items-center justify-center gap-1`,
        variantClassName[variant as keyof typeof variantClassName],
        className
      )}
      {...rest}
    >
      {preIcon && preIcon}
      {isLoading ? (
        <ClipLoader
          color="#ffffff"
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        text
      )}
      {postIcon && postIcon}
    </button>
  );
};

export default PrimaryButton;
