import React, { FC } from "react";
import ParentModal from "./ParentModal";
import { LiaTimesSolid } from "react-icons/lia";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void;
  title?: string;
  children: React.ReactNode;
  width?: number;
  subTitle?: string;
}

const SideModal: FC<ModalProps> = ({
  title,
  children,
  subTitle,
  onClose,
  ...rest
}) => {
  return (
    <ParentModal>
      <div
        className={`absolute top-0 right-0 rounded-xl flex items-start justify-between pt-[16px] px-6 pb-[24px] bg-white h-[95%] m-4 overflow-y-auto`}
        {...rest}
      >
        <div className="flex flex-col gap-1 w-full pt-6">
          {title && (
            <div>
              <p className="text-gray-800 font-bold text-[30px]">{title}</p>
              <p className=" text-gray-800 text-sm font-normal">{subTitle}</p>
            </div>
          )}
          <div>{children}</div>
        </div>

        {onClose && (
          <div
            onClick={onClose}
            className="flex items-center gap-1 bg-gray-50 rounded-lg py-[10px] px-[18px] absolute right-3 cursor-pointer"
          >
            <p className="text-gray-700 font-bold text-base">Close</p>
            <LiaTimesSolid size={20} />
          </div>
        )}
      </div>
    </ParentModal>
  );
};

export default SideModal;
