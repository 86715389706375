import { z } from "zod";
import {
  lowerCaseRegex,
  upperCaseRegex,
  specialCharcterRegex,
  numberRegex,
} from "@/utils/customExp";

export const email = z
  .string({ required_error: "Email is required." })
  .email("Invalid email address.");

export const phone_number = z
  .string({ required_error: "Phone number is required." })
  .trim()
  .min(9, { message: "Phone number can not be less than 9 digits." })
  .max(15, { message: "Phone number can not be more than 15 digits." })
  .superRefine((val, ctx) => {
    if (upperCaseRegex.test(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Phone Number can not contain uppercase letters.",
      });
    }
    if (lowerCaseRegex.test(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Phone Number can not contain lowercase letters.",
      });
    }
    if (specialCharcterRegex.test(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Phone Number can not contain special letters.",
      });
    }
  });

export const ContactSchema = z.object({
  full_name: z
    .string()
    .trim()
    .min(3, { message: "Full Name is required." })
    .refine(
      (value) => numberRegex.test(value) === false,
      "Numbers not allowed."
    ),
  phone_number,
  email,
  subject: z.string().trim().min(3, { message: "Subject is required." }),
});

export type TContaact = z.infer<typeof ContactSchema>;
