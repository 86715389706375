import React, { useState } from "react";
import useFileSelect from "@/hooks/useFileSelect";
import { copyToClipboard } from "@/utils/customExp";
import { FiCopy } from "react-icons/fi";
import { SlCamera } from "react-icons/sl";

const ProfileHeader = () => {
  const user = "corp";
  const [fileObject, setFileObject] = useState<File | File[] | null>(null);
  const { selectFile, imagePreview } = useFileSelect(setFileObject, {
    allowMultiple: false,
  });

  const handleFileChange = (
    mediaType: "Images" | "Videos",
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    selectFile(mediaType, event);
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2 relative mt-5">
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            handleFileChange("Images", e);
          }}
          className="hidden"
          id="file-input"
        />

        <label htmlFor="file-input" className="cursor-pointer">
          <div className="w-[64px] h-[64px] bg-[#a7a6a6] rounded-full flex justify-center items-center relative">
            {imagePreview ? (
              <img
                src={imagePreview as string}
                alt="profile picture preview"
                className="object-cover w-[64px] h-[64px] rounded-full"
              />
            ) : (
              <div className="w-full h-full rounded-full"></div>
            )}

            <div className="absolute inset-0 flex justify-center items-center">
              <div className="bg-brand-600 h-6 w-6 rounded-full flex items-center justify-center">
                <SlCamera className="text-white" size={16} />
              </div>
            </div>
          </div>
        </label>
        <div className="flex flex-col gap-1">
          <p className="text-gray-800 font-bold text-lg">Jimoh Festus</p>
          <p className="text-gray-600 font-mednium text-sm">
            Jimoh007@gmail.com
          </p>
        </div>
      </div>

      {user !== "corp" && (
        <div className="border-l border-l-gray-200">
          <div className="ml-4 flex flex-col gap-1">
            <p className="text-gray-800 text-sm">Coupon Code</p>
            <div className="flex items-center gap-2">
              <p className="text-brand-600 text-base font-bold">5486</p>
              <FiCopy
                className="text-brand-600 cursor-pointer"
                size={20}
                onClick={() => copyToClipboard("5486")}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileHeader;
