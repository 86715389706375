import cursorHover from "@/assets/svgs/cursor-hover.svg";
import { useScreenContext } from "../context/screenContext";
import Box from "@/assets/svgs/box.svg";
import { GoDotFill } from "react-icons/go";
import { FaRegCircleCheck } from "react-icons/fa6";
import PrimaryButton from "@/components/Button/PrimaryButton";
import { FaTimes } from "react-icons/fa";

const OrderDetails = () => {
  const { orderId, setOrderId } = useScreenContext();

  const data = { title: orderId };

  return (
    <div className="relative">
      {!data.title && (
        <div className="flex flex-col items-center justify-center h-screen">
          <img src={cursorHover} alt="empty image" />
          <p className="text-gray-700 text-sm font-medium">
            Select a Package to display here
          </p>
        </div>
      )}

      {data.title && (
        <div className="">
          <div className="flex gap-3">
            <div className="bg-brand-25 flex items-center justify-center w-[42px] h-[42px] rounded-full">
              <img src={Box} alt="box" />
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-gray-700 font-bold text-sm">
                Standing Fan (Black)
              </p>
              <p className="text-gray-800 text-sm font-normal">
                Tracking ID: 5654F4DSA545Q
              </p>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex flex-col space-y-3">
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Recipient Name
                  </p>
                  <p className="text-gray-700 font-bold text-sm">
                    Jimoh Adigun Taiwo
                  </p>
                </div>
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Rider’s Name
                  </p>
                  <p className="text-gray-700 font-bold text-sm">Unavailable</p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Delivery type
                  </p>
                  <p className="text-gray-700 font-bold text-sm">Express</p>
                </div>
                <div className="flex-1">
                  <p className="text-gray-600 text-xs font-medium">
                    Amount Payable (N)
                  </p>
                  <p className="text-gray-700 font-bold text-sm">5,000.00</p>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 mt-5 p-4 rounded-2xl">
              <div className="flex-1 flex gap-1">
                <GoDotFill className="text-brand-100" size={20} />
                <div>
                  <p className="text-gray-600 text-xs font-medium">From</p>
                  <p className="text-gray-700 text-sm font-medium">
                    Idumota Store, Orile Agege
                  </p>
                </div>
              </div>
              <div className="flex-1 flex gap-1 mt-5">
                <GoDotFill className="text-green-primary" size={20} />
                <div>
                  <p className="text-gray-600 text-xs font-medium">
                    Shipped to
                  </p>
                  <p className="text-greay-700 text-sm font-medium">
                    32, Sangodiya Avenue
                  </p>
                </div>
              </div>
              <div className="flex-1 flex items-center gap-1 mt-8 ml-3">
                <p className="text-gray-600 font-bold text-sm">
                  Status: Picked up
                </p>
                <FaRegCircleCheck color="#F2994A" />
              </div>
            </div>

            <div className="mt-7 space-y-6">
              <div className="">
                <p className="text-gray-700 font-bold text-base">Who to Pay</p>
                <p className="text-gray-600 text-sm">Reciever</p>
              </div>
              <div className="">
                <p className="text-gray-700 font-bold text-base">
                  Payment Status
                </p>
                <p className="text-orange-primary text-sm">Pending</p>
              </div>
            </div>
          </div>

          <PrimaryButton
            text="Close"
            variant="secondary"
            className="bg-gray-50 w-full text-center text-gray-700 mt-[30%]"
            style={{ color: "#344054" }}
            postIcon={<FaTimes />}
            onClick={() => setOrderId("")}
          />
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
