import { IoIosArrowDown } from "react-icons/io";
import Divider from "../Divider";
import { HTMLAttributes, useRef } from "react";

interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  isCollapsed: boolean;
  toggleSection: () => void;
  children: React.ReactNode;
  titleStyle?: any;
  isBorderBotton?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  isCollapsed,
  toggleSection,
  children,
  titleStyle,
  isBorderBotton = true,
  ...rest
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="">
      <div
        className="flex items-center justify-between cursor-pointer pb3 pt-6 transition-all duration-300 ease-in-out"
        onClick={toggleSection}
        {...rest}
      >
        <p className={`text-gray-800 text-base font-medium ${titleStyle}`}>
          {title}
        </p>
        <IoIosArrowDown
          size={20}
          className={`text-[#667085] transform transition-transform duration-300 ${
            isCollapsed ? "rotate-180" : ""
          }`}
        />
      </div>
      <div
        ref={contentRef}
        style={{
          maxHeight: isCollapsed
            ? `${contentRef.current?.scrollHeight}px`
            : "0px",
        }}
        className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
          isCollapsed ? "opacity-100 my-3" : "opacity-0 mb-3"
        }`}
      >
        {children}
      </div>
      {isBorderBotton && <Divider />}
    </div>
  );
};

export default Accordion;
