import axios from "axios";
import { getToken, removeToken } from "../helpers/authHelpers";
import { store } from "@/stores/store";
import { clearUser } from "@/stores/reducers/user-reducers";
import { setErrors } from "@/stores/reducers/app-reducers";
import { BASE_URL } from "@/constants/base_urls";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@/constants/app-routes";

export interface NetworkRequestReturnType {
  code: number;
  status: "success" | "failed" | "pending";
  data?: any;
  headers?: Record<string, string>;
}

type GetRequestType = {
  url: string;
  payload?: any | never;
  headers?: Record<string, string>;
  useBaseUrl?: boolean;
  ignoreError?: boolean;
};

type PostRequestType = {
  url: string;
  payload: any;
  headers?: Record<string, string>;
  useBaseUrl?: boolean;
  ignoreError?: boolean;
};

export interface NetworkReturnType {
  request: {
    (
      method: "GET" | "DELETE",
      props: GetRequestType
    ): Promise<NetworkRequestReturnType>;
    (
      method: "POST" | "PUT",
      props: PostRequestType
    ): Promise<NetworkRequestReturnType>;
  };
}

const source = axios.CancelToken.source();

export const ApiRequest = (): NetworkReturnType => {
  const navigate = useNavigate();
  const apiClient = axios.create({
    // withCredentials: true,
  });

  apiClient.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleErrors = async (
    error: any,
    props: GetRequestType | PostRequestType
  ) => {
    if (process.env.NODE_ENV !== "production") {
      console.warn("[Axios Error....]", error);
    }

    const statusCode = error?.response?.status;
    let errorMessage: any = [];
    if (error.response && error.response.data) {
      const message = error.response.data.message;

      if (typeof message === "object" && message !== null) {
        errorMessage = Object.values(message).flat();
      } else if (typeof message === "string") {
        errorMessage = [message];
      } else {
        errorMessage = [error.response.data.error ?? "An Error occured!"];
      }

      if (statusCode === 401) {
        removeToken();
        store.dispatch(clearUser());
        // navigate("/");
      } else if (statusCode === 403) {
        if (!props.ignoreError) {
          store.dispatch(setErrors(errorMessage));
          store.dispatch(clearUser());
          navigate(APP_ROUTES.VERIFY_OTP, {
            state: "register",
          });
        }
      } else {
        if (!props.ignoreError) store.dispatch(setErrors(errorMessage));
      }
    }
  };

  const request = async (
    method: "GET" | "POST" | "PUT" | "DELETE",
    props: GetRequestType | PostRequestType
  ): Promise<NetworkRequestReturnType> => {
    const { url, payload, headers, useBaseUrl } = props;
    const baseURL = BASE_URL;
    // const fullUrl = `${baseURL}/api${url}`;
    const token = getToken();
    let fullUrl = "";

    if (!useBaseUrl) {
      fullUrl = `${baseURL}/api${url}`;
    } else {
      fullUrl = url;
    }

    const config = {
      method,
      url: fullUrl,
      data: payload,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...headers,
      },
      //   baseURL,
      cancelToken: source.token,
      props,
    };

    try {
      const response = await apiClient.request<NetworkRequestReturnType>(
        config
      );
      return {
        code: response.status,
        status: "success",
        data: response.data,
      };
    } catch (error: any) {
      handleErrors(error, props);
      return {
        code: error.response.status,
        status: "failed",
        data: error?.response?.data?.message,
      };
    }
  };

  return {
    request,
  };
};
