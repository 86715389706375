import PrimaryButton from "@/components/Button/PrimaryButton";
import Divider from "@/components/Divider";
import { useNavigate } from "react-router-dom";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="border border-gray-100 rounded-lg bg-gray-50 py-6 px-2 flex justify-between items-center">
        <div className="flex flex-col gap-y-2">
          <p className="text-gray-500 text-sm">Bill Invoice to:</p>
          <p className="text-gray-500 font-medium text-2xl">Idris Limited</p>
          <p className="text-gray-500 text-sm">idrisltd@yahoo.com</p>
        </div>
        <div className="flex flex-col items-end gap-y-2">
          <p className="text-gray-500 text-sm font-medium">
            Issue date: 10th November, 2024
          </p>
          <p className="text-brand-600 font-medium text-sm">
            Due date: 30th October, 2024
          </p>
        </div>
      </div>
      <div className="mt-5">
        <Divider />
        <div className="flex justify-between mt-2">
          <div className="flex flex-col gap-y-6">
            <p className="text-gray-600 text-sm">Total Deliveries</p>
            <p className="text-gray-600 text-sm">Express Deliveries</p>
            <p className="text-gray-600 text-sm">Standard Deliveries</p>
            <p className="text-gray-600 text-sm">Avg. Delivery</p>
            <p className="text-gray-600 text-sm">Discount</p>
            <p className="text-gray-600 text-sm">Total Amount</p>
          </div>
          <div className="flex flex-col items-end gap-y-4">
            <p className=" text-gray-700 text-xl font-medium">20</p>
            <p className=" text-gray-700 text-xl font-medium">12</p>
            <p className=" text-gray-700 text-xl font-medium">8</p>
            <p className=" text-gray-700 text-xl font-medium">N1500.00</p>
            <p className=" text-gray-700 text-xl font-medium">N0.00</p>
            <p className=" text-gray-700 text-xl font-medium">N50,000.00</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[18px] mt-20">
        <PrimaryButton variant="primary" text="Continue to payment" />
        <PrimaryButton
          variant="light"
          text="Go back"
          className="bg-brand-25 w-[172px]"
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default InvoiceDetails;
