import PrimaryButton from "@/components/Button/PrimaryButton";
import Input from "@/components/Input/Input";
import TextArea from "@/components/Input/TextArea";
import Label from "@/components/Label/Label";
import { ContactSchema, TContaact } from "@/types/validations/contact";
import { MdOutlineMail } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGlobalContext } from "@/stores/context/GlobalContext";

const Contact = () => {
  const { contact } = useGlobalContext();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<TContaact>({
    mode: "onBlur",
    resolver: zodResolver(ContactSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<TContaact> = async (data) => {
    console.log(data);
  };

  return (
    <section
      ref={contact}
      className="flex flex-col items-center mt-10 bg-gray-50"
    >
      <div className="width-container">
        <div className="flex flex-col md:flex-row items-center justify-between mt-2">
          <div className="">
            <h2 className="text-gray-900 text-[30px] md:text-[36px] font-bold w-full max-w-[424px]">
              Get in touch with us, we’re ready to assist you
            </h2>
            <p className="text-gray-700 text-sm md:text-base w-full max-w-[312px] mt-3 mb-7">
              It's very easy to get in touch with us. Just use the contact form
              or via any of the contact information provided below
            </p>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-[#F6F6F6] w-full max-w-[713px] my-4 px-4"
          >
            {/* form here */}
            <div className="flex flex-col md:flex-row items-center gap-4">
              <Input
                isRequired
                label="Full Name"
                placeholder="Full Name"
                {...register("full_name")}
                error={errors.full_name?.message}
              />
              <Input
                preIcon={<MdOutlineMail size={20} className="text-gray-500" />}
                isRequired
                label="Email Address"
                placeholder="example@mail.com"
                {...register("email")}
                error={errors.email?.message}
              />
            </div>
            <div className="w-full md:w-[50%]">
              <Label label="Phone Number" isRequired />
              <PhoneInput
                placeholder="90722245789"
                country={"ng"}
                onChange={(value) => setValue("phone_number", value)}
                inputStyle={{
                  width: "100%",
                  borderRadius: "8px",
                  fontSize: "14px",
                }}
              />
              {errors.phone_number?.message && (
                <small className="px-3 text-red-500">
                  {errors.phone_number?.message}
                </small>
              )}
            </div>
            <TextArea
              rows={8}
              label="Subject"
              isRequired
              placeholder="Text goes here"
              {...register("subject")}
              error={errors.subject?.message}
            />
            <PrimaryButton
              type="submit"
              text="Send Message"
              variant="primary"
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
