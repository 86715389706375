import Divider from "@/components/Divider";
import Notifications from "./components/notifications";
import RecentOrder from "./components/recent-order";
import TrackingInput from "./components/tracking-input";
import UserLocation from "./components/user-location";
import Ads from "@/components/ads";
import SendPackage from "@/components/send-package";

const Dashboard = () => {
  return (
    <div className="flex w-full min-hscreen">
      <div className="border-r border-r-gray-200 w-[70%] py-5 overflow-y-scroll h-screen px-8">
        <UserLocation />
        <TrackingInput />
        <RecentOrder />
      </div>
      <div className="w-[30%] min-hscreen sticky top-0 left-0">
        <div className="h-screen overflow-y-scroll overflow-hidden">
          <Notifications />
          <div className="px-4">
            <Divider />
            <SendPackage />
            <Ads />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
