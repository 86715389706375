import ProfileTabs from "./profile-tabs";
import ProfileHeader from "./profile-header";

const MyProfile = () => {
  return (
    <>
      <ProfileHeader />
      <ProfileTabs />
    </>
  );
};

export default MyProfile;
