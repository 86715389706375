import Bike from "@/assets/images/bike.png";
import { IoMdCheckmark } from "react-icons/io";
import Stripe1 from "@/assets/icons/stripe1.svg";
import Stripe2 from "@/assets/icons/stripe2.svg";
import MdStripe1 from "@/assets/svgs/md-stripe.svg";
import MdStripe2 from "@/assets/svgs/md-stripe2.svg";
import InputWithButton from "@/components/Input/InputWithButton";
import { useState } from "react";
import { useDisclosure } from "@/hooks/useDisclosure";
import TrackingDetails from "@/components/TrackingDetails";

const Hero = () => {
  const [trackingId, setTrackingId] = useState("");
  const {
    isOpen: openModal,
    onClose: onModalClose,
    onOpen: onOpenOpen,
  } = useDisclosure();
  const checks = [
    "Flexible payment options for orders and riders.",
    "Flexible map directions for easy pickup and delivery of orders.",
    "Secure way to add bikes and manage the riders.",
  ];

  // const TrackPackage = () => {
  //   navigate(APP_ROUTES.TRACK, { state: trackingId });
  // };

  return (
    <>
      <div className="flex flex-col items-center px-3">
        <div className="w-full lg:max-w-[1336px] bg-gradient-to-b lg:bg-gradient-to-r from-[#AAD2E75C] to-[#F9FAFB] h-fit lg:h-[678px] rounded-[32px] mt-10 z-10 pb-20 relative">
          <div className="flex flex-col lg:flex-row items-center justify-center">
            <div className="px-3 lg:pl-10 mt-4 lg:mt-32">
              <div className="bg-brand-25 rounded-full py-2 px-2 w-fit">
                <p className="text-gray-700 text-xs lg:text-sm font-medium">
                  Currently Serving Lagos, Nigeria.
                </p>
              </div>
              <p className="text-gray-900 font-bold text-[36px] lg:text-[48px] w-full max-w[358px]">
                Fast, Secure, and Trusted Package Delivery Solutions
              </p>
              <div className="flex flex-col gap-4 mt-10">
                {checks.map((check) => (
                  <div className="flex flex-row items-center gap-2">
                    <div className="bg-brand-25 rounded-full w-[28px] h-[28px] flex flex-row items-center justify-center">
                      <IoMdCheckmark className="text-brand-600" />
                    </div>
                    <p className="text-[#475467] text-sm font-semibold">
                      {check}
                    </p>
                  </div>
                ))}
              </div>
              {/* <div className="mt-7 w-full max-w-[502px]">
                <InputWithButton
                  value={trackingId}
                  onChange={(e) => setTrackingId(e.target.value)}
                  placeholder="Enter Tracking ID here to track package"
                  buttonText="Track"
                  onButtonClick={() => {
                    onOpenOpen();
                  }}
                />
              </div> */}
            </div>
            <div className="relative w[649px] lg:h-[514px]">
              <img
                src={Bike}
                alt="delivery bike"
                className="w-full lg:pr-10 lg:mt-12"
              />
              <div className="lg:hidden block absolute top-0 -z-10">
                <img src={MdStripe1} alt="" />
              </div>
            </div>
          </div>
          <div className="hidden lg:block absolute right-0 bottom-0 lg:top-0 -z-10">
            <img src={Stripe2} alt="" />
          </div>
          <div className="hidden lg:block absolute left-0 lg:left-[42%] bottom-0 lg:top-0 -z-10">
            <img src={Stripe1} alt="" />
          </div>
          <div className="lg:hidden block absolute bottom-0 right-0 -z-10">
            <img src={MdStripe2} alt="" />
          </div>
        </div>
      </div>
      {
        openModal && <TrackingDetails trackingId={trackingId}  onClose={onModalClose}/>
      }
    </>
  );
};

export default Hero;
