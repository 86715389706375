import { CiBellOn } from "react-icons/ci";
import { IntNotificationsType } from "@/models/Notification";

const Notifications = () => {
  const data: any[] = [];

  return (
    <div>
      {data.length === 0 && (
        <div className="mt-[60px] flex flex-col justify-center items-center">
          <div className="flex items-center justify-center w-20 h-20 bg-[#DFDFDF40] rounded-full">
            <CiBellOn size={32} />
          </div>
          <p className="text-gray-500 text-sm text-center mt-[30px]">
            Your Notifications will appear here
          </p>
        </div>
      )}

      {data.length > 0 && (
        <div className="mt-4 flex flex-col gap-4">
          {data?.map((noty: any, idx: any) => (
            <div className="mt4">
              {/* <p className="text-gray-500 text-[12px] font-medium">Today</p> */}
              <div className="flex gap-4 mt-3">
                {/* icon here */}
                <div className="flex flex-col gap-3">
                  <p className="text-gray-800 text-sm">
                    Package <span className="font-bold">(#563235SA5D)</span>{" "}
                    delivered
                  </p>
                  <p className="text-gray-500 text-sm">
                    Your package has been delivered to Bayo Idris (54, wulemotu
                    street)
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
