import { APP_ROUTES } from "@/constants/app-routes";
import { useNavigate } from "react-router-dom";
import EmptyBox from "@/assets/svgs/emptyBox.svg";
import { Orders } from "@/models/Order";
import Box from "@/assets/svgs/box2.svg";
import { GoDotFill } from "react-icons/go";
import { FaRegCircleCheck } from "react-icons/fa6";

const RecentOrder = () => {
  const navigate = useNavigate();
  const data: any[] = [{ o: "" }, { 1: "" }, { 2: "" }, { 3: "" }];

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <p className="text-gray-600 text-lg font-medium">Recent Orders</p>
        <div
          className="text-brand-600 text-sm font-medium cursor-pointer"
          onClick={() => navigate(APP_ROUTES.ACTIVITY)}
        >
          View All
        </div>
      </div>
      {data.length === 0 && (
        <div className="mt-[60px]">
          <div className="flex items-center justify-center">
            <img src={EmptyBox} alt="" className="w-[150px] h-[150px]" />
          </div>
          <p className="text-gray-800 text-sm text-center">
            Start sending packages <br /> to see activity here
          </p>
        </div>
      )}

      {data.length > 0 && (
        <div className="flex flex-col gap-4">
          {data?.map((order) => (
            <div className="border-[0.5px] border-gray-200 rounded-2xl w-full h[138px] mt-3 p-6">
              <div className="flex justify-between items-center">
                <div className="flex gap-3">
                  <img src={Box} alt="box" />
                  <div>
                    <p className="text-gray-700 font-bold text-sm">
                      Standing Fan (Black)
                    </p>
                    <p className="text-gray-800 text-sm font-normal">
                      Tracking ID: 5654F4DSA545Q
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-gray-600 font-bold text-sm">
                    Status: Picked up
                  </p>
                  <FaRegCircleCheck color="#32D583" />
                </div>
              </div>
              <div className="flex mt-6">
                <div className="flex-1 flex gap-1">
                  <GoDotFill className="text-brand-100" size={20} />
                  <div>
                    <p className="text-gray-600 text-xs font-medium">From</p>
                    <p className="text-gray-700 text-sm font-medium">
                      Idumota Store, Orile Agege
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex">
                  <GoDotFill className="text-green-primary" size={20} />
                  <div>
                    <p className="text-gray-600 text-xs font-medium">
                      Shipped to
                    </p>
                    <p className="text-gray-700 text-sm font-medium">
                      32, Sangodiya Avenue
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecentOrder;
