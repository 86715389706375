import PrimaryButton from "@/components/Button/PrimaryButton";
import OtpInput from "@/components/Input/OtpInput";
import Seo from "@/components/Seo";
import { Toast } from "@/config/toast";
import { useAuthServices } from "@/services/auth";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const VerifyOtp = () => {
  const [otp, setOtp] = useState<string>("");
  const { authVerifyPasswordOtp, authVerifyRegistrationOtp, authResendOtp } =
    useAuthServices();
  const { state } = useLocation();
  const [resendOtptimeRemaining, setResendOtpTimeRemaining] = useState(60);
  const handleOtpChange = (value: string) => {
    setOtp(value);
  };

  const verifyOtpFunc = async () => {
    if (otp.length !== 4) {
      Toast.error("Please enter a 4-digit OTP");
      return;
    }
    if (state?.type === "password") {
      await authVerifyPasswordOtp({ verification_token: otp });
    } else {
      await authVerifyRegistrationOtp({ verification_token: otp });
    }
  };

  const { mutate, isPending } = useMutation({
    mutationFn: verifyOtpFunc,
  });

  const resendOtp = async () => {
    await authResendOtp({ email: state?.email });
    setResendOtpTimeRemaining(60);
  };

  useEffect(() => {
    if (resendOtptimeRemaining > 0) {
      const intervalId = setInterval(() => {
        setResendOtpTimeRemaining((prevTime: number) => prevTime - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [resendOtptimeRemaining]);

  return (
    <section className="w-[450px]">
      <Seo title="Point2 | Verify otp" />
      <h1 className="text-gray-700 text-[30px] font-medium">
        Verify your account
      </h1>
      <p className="text-gray-900 text-sm font-medium mt-1">
        Enter the 4-digit code we sent to {state?.email} and{" "}
        <span className="text-gray-600 font-semibold">+2349084651462</span>.{" "}
        <span className="font-bold text-brand-600 cursor-pointer">
          Change Phone number
        </span>
      </p>
      <div className="mt-10">
        <OtpInput length={4} onChange={handleOtpChange} />
      </div>
      <div className="mt-14">
        <PrimaryButton
          onClick={() => mutate()}
          isLoading={isPending}
          disabled={isPending}
          text="Verify my Account"
          className="w-full"
          variant="primary"
        />
      </div>
      <div className="mt-5">
        <p className="text-gray-700 text-sm font-medium text-center">
          Haven’t received the code yet?
        </p>
        <div onClick={resendOtp} className="cursor-pointer">
          <p className="text-brand-600 text-sm font-medium text-center">
            {resendOtptimeRemaining === 0
              ? "Tap to resend OTP"
              : `Resend code in 0:${resendOtptimeRemaining}`}
          </p>
        </div>
      </div>
    </section>
  );
};

export default VerifyOtp;
