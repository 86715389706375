import Gift from "@/assets/svgs/giftPackage.svg";

const Ads = () => {
  return (
    <div className="flex items-center justify-between w-full h-[114px] bg-[#FBF1D2] rounded-lg pl-4 relative mt-6">
      <div className="py2">
        <p className="text-[13px] text-gray-800 text-sm font-medium w-[185px]">
          Use Point 2 riders 10 times and unlock a free delivery as your reward!
        </p>
        <p className="font-bold text-xl text-gray-950 mt-2">0/10</p>
      </div>

      <div className="absolute right-0 bottom-0">
        <img src={Gift} alt="" />
      </div>
    </div>
  );
};

export default Ads;
