import Navbar from "@/layouts/Navbar";
import Footer from "@/layouts/Footer";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  return (
    <div className="flex min-h-screen flex-col">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default AppLayout;
