import PrimaryButton from "@/components/Button/PrimaryButton";
import Seo from "@/components/Seo";
import { FaCircleCheck } from "react-icons/fa6";

const PasswordResetSuccess = () => {
  return (
    <section className="w-[488px]">
      <Seo title="Point2 | Reset password" />
      <h1 className="text-gray-700 text-[30px] font-medium">Success</h1>
      <p className="text-gray-900 text-sm font-medium mt-1">
        Password reset successfully, login with new password
      </p>
      <div className="flex flex-col items-center justify-center mt-20">
        <div className="w-[128px] h-[128px] rounded-full bg-[#CFF7D3] flex flex-col justify-center items-center">
          <FaCircleCheck className="" size={90} color="#14AE5C" />
        </div>
      </div>
      <div className="mt-12">
        <PrimaryButton
          text="Proceed to Login"
          variant="primary"
          className="w-full"
        />
      </div>
    </section>
  );
};

export default PasswordResetSuccess;
