import Ceo from "@/assets/images/ceo.png";
import HOM from "@/assets/images/Hom.png";
import HOT from "@/assets/images/Hot.png";
import supervisor from "@/assets/images/supervisor.png";
import Twitter from "@/assets/icons/twitter.png";
import Linkedin from "@/assets/icons/linkedin.png";
import { useGlobalContext } from "@/stores/context/GlobalContext";

const TeamMembers = () => {
  const { team } = useGlobalContext();
  return (
    <section ref={team} className="mt-24 flex flex-col items-center px-3 md:px-0">
      <div className="width-container bg-brand-25 rounded-[32px]">
        <div className="p-4">
          <h2 className="text-gray-800 text-[30px] md:text-[36px] font-bold text-center md:text-left">
            Our Team Members
          </h2>
          <p className="text-gray-700 text-base mt- text-center md:text-left">
            Meet our team of experts at Point2 Logistics{" "}
          </p>
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mt-8">
            <Teams
              image={Ceo}
              linkedin="/"
              twitter="/"
              name="Gbaye Yusuf"
              position="CEO"
            />
            <Teams
              image={HOM}
              linkedin="/"
              twitter="/"
              name="Nathaniel Obaika"
              position="Head of Marketing"
            />
            <Teams
              image={HOT}
              linkedin="/"
              twitter="/"
              name="Noah Yusuf"
              position="Head of Team"
            />
            <Teams
              image={supervisor}
              linkedin="/"
              twitter="/"
              name="Michael Olanrewaju"
              position="Supervisor"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const Teams = ({
  image,
  linkedin,
  twitter,
  name,
  position,
}: {
  image: string;
  linkedin: string;
  twitter: string;
  name: string;
  position: string;
}) => (
  <section className="bg-white h-[286px] w-[286px] rounded-[16px] p-2">
    <img src={image} alt="ceo-image" />
    <div className="flex justify-between mt-2">
      <div>
        <p className="text-gray-600 text-lg font-bold">{name}</p>
        <p className="text-gray-600 text-sm">{position}</p>
      </div>
      <div className="flex items-center gap-1">
        <a href={linkedin} className="w-6 h-6">
          <img src={Linkedin} alt="" />
        </a>
        <a href={twitter} className="w-6 h-6">
          <img src={Twitter} alt="" />
        </a>
      </div>
    </div>
  </section>
);

export default TeamMembers;
