import { Dispatch, SetStateAction, useState } from "react";

type UseFileSelectConfig = {
  allowMultiple: boolean;
};

const useFileSelect = (
  setFileObect?: Dispatch<SetStateAction<File[] | File | null>>,
  config: UseFileSelectConfig = { allowMultiple: false }
) => {
  const [imagePreview, setImagePreview] = useState<string[] | string | null>(
    config.allowMultiple ? [] : null
  );

  const selectFile = async (
    mediaTypes: "Images" | "Videos",
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (config.allowMultiple) {
        const selectedFiles = Array.from(files);
        const previews: string[] = [];

        selectedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            previews.push(reader.result as string);
            setImagePreview([...previews]);
          };
          reader.readAsDataURL(file);
        });
        setFileObect && setFileObect(selectedFiles);
      } else {
        const file = files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
        if (mediaTypes === "Images") {
          setFileObect && setFileObect(file);
        } else {
          setFileObect && setFileObect(file);
        }
      }
    }
  };

  return {
    selectFile,
    imagePreview,
    setImagePreview,
  };
};

export default useFileSelect;
